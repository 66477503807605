<template>
  <div style="overflow: hidden">
    <rxNavBar title="投诉完成"></rxNavBar>
    <div class="content">
      <div class="part">
        <span class="part-subtitle">投诉信息</span>
        <div class="otherExpenses-inputpart">
          <div class="otherExpenses-inputpart-row">
            <textarea v-model="complaintInfo.roomDetailedAddress" :readonly="isModification"/>
<!--            <div class="part-inputpart-row-right editing"></div>-->
          </div>
          <div class="otherExpenses-inputpart-column">
            <div class="otherExpenses-inputpart-row-bottom">
              <div class="otherExpenses-inputpart-row-left">
                <span class="part-inputpart-row-header">投诉人：</span>
                <span>{{ complaintInfo.complaintUserName }}</span>
              </div>
              <div class="otherExpenses-inputpart-row-right">
                <span class="part-inputpart-row-header">联系方式：</span>
                <span>{{ complaintInfo.complaintMobile }}</span>
              </div>
            </div>
            <div class="otherExpenses-inputpart-row-bottom">
              <span class="part-inputpart-row-header">投诉时间：</span>
              <span class="part-inputpart-row-redtext">{{ complaintInfo.addTime }}</span>
            </div>
            <div>
              <span class="part-inputpart-row-header">投诉原因：</span>
              <span class="part-inputpart-row-wrap">{{ complaintInfo.complaintDetail }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="part">
        <span class="part-subtitle">受理投诉</span>
        <div class="otherExpenses-inputpart">
          <div class="otherExpenses-inputpart-column">
            <div class="otherExpenses-inputpart-row-bottom">
              <div class="otherExpenses-inputpart-row-left">
                <span class="part-inputpart-row-header">受理人：</span>
                <span>{{ acceptComplaintsInfo.staffName }}</span>
              </div>
              <div class="otherExpenses-inputpart-row-right">
                <span class="part-inputpart-row-header">投诉对象：</span>
                <span>{{ acceptComplaintsInfo.complaintTargetGlobaluserName }}</span>
              </div>
            </div>
            <div class="otherExpenses-inputpart-row-bottom">
              <div class="otherExpenses-inputpart-row-left">
                <span class="part-inputpart-row-header">投诉类型：</span>
                <span>{{ acceptComplaintsInfo.complaintTypeStr }}</span>
              </div>
              <div class="otherExpenses-inputpart-row-right">
                <span class="part-inputpart-row-header">投诉部门：</span>
                <span>{{ acceptComplaintsInfo.departmentName }}</span>
              </div>
            </div>
            <div class="otherExpenses-inputpart-row-bottom">
              <span class="part-inputpart-row-header">受理时间：</span>
              <span class="part-inputpart-row-redtext">{{ acceptComplaintsInfo.acceptTime }}</span>
            </div>
            <div>
              <span class="part-inputpart-row-header">备注：</span>
              <span class="part-inputpart-row-wrap">{{ acceptComplaintsInfo.remarks }}</span>
            </div>
          </div>
        </div>
        <div class="part-inputpart-row picList" v-if="picList.length">
          <div v-for="(pic,index) in picList" :key="index" @click="previewImg(index)">
            <van-image
                class="pic"
                width="95"
                height="75"
                radius="10"
                :src="pic"
                fit="contain"
            />
          </div>
          <div class="num">
            {{ picList == null ? '0' : picList.length }}图
          </div>
        </div>
      </div>
      <div class="part" v-if="historyList && historyList.length" v-for="(item,index) in historyList" :key="index">
        <span class="part-subtitle">{{item.complaintStatus=='2' ? '处理结果' : '核验投诉'}}</span>
        <div class="otherExpenses-inputpart">
          <div class="otherExpenses-inputpart-column">
            <div class="otherExpenses-inputpart-row-bottom">
              <div class="otherExpenses-inputpart-row-left">
                <span class="part-inputpart-row-header">受理人：</span>
                <span>{{ item.staffName }}</span>
              </div>
              <div class="otherExpenses-inputpart-row-right">
                <span class="part-inputpart-row-header">受理状态：</span>
                <span>{{ item.dealResultStr }}</span>
              </div>
            </div>
            <div class="otherExpenses-inputpart-row-bottom">
              <span class="part-inputpart-row-header">受理时间：</span>
              <span class="part-inputpart-row-redtext">{{ item.addTime }}</span>
            </div>
            <div>
              <span class="part-inputpart-row-header">{{item.complaintStatus=='2' ? '解决处理描述' : '验收备注'}}：</span>
              <span class="part-inputpart-row-wrap">{{ item.remarks }}</span>
            </div>
          </div>
        </div>
      </div>


      <!--      <span class="part-subtitle" style="margin-left: 15px">审核信息</span>-->
      <!--      <div class="part">-->
      <!--        <div class="otherExpenses-inputpart">-->
      <!--          <div class="otherExpenses-inputpart-column">-->
      <!--            <div class="otherExpenses-inputpart-row-bottom">-->
      <!--              <span class="part-inputpart-row-header">审核人：</span>-->
      <!--              <span>{{ audit.verifier }}</span>-->
      <!--            </div>-->
      <!--            <div class="otherExpenses-inputpart-row-bottom">-->
      <!--              <span class="part-inputpart-row-header">审核时间：</span>-->
      <!--              <span class="part-inputpart-row-redtext">{{ audit.auditTime }}</span>-->
      <!--            </div>-->
      <!--            <div class="otherExpenses-inputpart-row-bottom">-->
      <!--              <span class="part-inputpart-row-header">审核结果：</span>-->
      <!--              <span :class="audit.auditResult=='通过'?'green':'red'">{{ audit.auditResult }}</span>-->
      <!--            </div>-->
      <!--            <div>-->
      <!--              <span class="part-inputpart-row-header">审核意见：</span>-->
      <!--              <span class="part-inputpart-row-wrap">{{ audit.auditOpinion }}</span>-->
      <!--            </div>-->
      <!--            <div class="otherExpenses-inputpart-row-bottom">-->
      <!--              <span class="part-inputpart-row-header">服务人员评价：</span>-->
      <!--              <van-rate v-model="audit.evaluation"-->
      <!--                        :count="audit.num"-->
      <!--                        color="#FF5D3B"-->
      <!--                        void-icon="star"-->
      <!--                        readonly-->
      <!--              ></van-rate>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="part">
        <span class="part-subtitle">受理结果</span>
        <div class="part-inputpart">
          <div class="part-inputpart-row">
            <div :class=" 0==acceptanceResult.processingState.trim().length ? 'content-none' : 'content-have'"></div>
            <span class="part-inputpart-row-header">处理状态</span>
            <div>
                            <span class="part-inputpart-isSupport part-inputpart-row-header"
                                  :class="'2'==acceptanceResult.processingState ? 'part-inputpart-row-redtext' : 'part-inputpart-row-graytext' "
                                  @click="isEffective">有效</span>
              <span class="content-divide content-divide-position part-inputpart-row-short-graytext">|</span>
              <span class="part-inputpart-woman part-inputpart-row-header"
                    :class="'4'==acceptanceResult.processingState ? 'part-inputpart-row-redtext' : 'part-inputpart-row-graytext' "
                    @click="noEffective">无效</span>
            </div>
          </div>
        </div>
        <div class="part-inputpart">
          <div class="part-inputpart-textarea">
            <div class="part-inputpart-row">
<!--              <span :class=" 0!=acceptanceResult.processDescription.trim().length ? 'content-have' : 'content-none' "></span>-->
              <span class="part-inputpart-row-header" style="margin-left: 15px">处理描述</span>
            </div>
            <textarea v-model="acceptanceResult.processDescription"></textarea>
          </div>
        </div>
        <div @click="submit" class="part-button" :class=" !id || !acceptanceResult.processingState ? '' : 'part-button-enabled' ">保存</div>
      </div>

      <div class="part" v-if="acceptanceRecordList&&acceptanceRecordList.length">
        <span class="part-subtitle">受理记录</span>
        <div v-for="(item,index) in acceptanceRecordList" :key="index">
          <div class="traversalRecord">
            <div class="redRing">
              <div v-if="0==index" class="redVertical-upline"></div>
              <div v-if="index!=acceptanceRecordList.length-1" class="redVertical-downline"></div>
              <div class="redDot"></div>
            </div>
          </div>
          <div class="traversalRecord-inputpart-column">
            <div class="otherExpenses-inputpart-row-bottom">
              <span class="part-inputpart-row-header">{{item.complaintLevelStr}}</span>
              <span class="part-inputpart-row-graytime">{{item.addTime}}</span>
            </div>
            <div class="otherExpenses-inputpart-row-bottom">
              <span class="part-inputpart-row-normaltext">{{item.staffName}}</span>
              <pre>  </pre>
              <span style="text-overflow: ellipsis;overflow: hidden;" class="part-inputpart-row-graytext">{{item.remarks}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  Image as VanImage,
  ImagePreview,
  Rate
} from 'vant'
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar"
import {
  afterRentComplaintAcceptInit,
  queryBaseData,
  queryComplaintHistory,
  updateAfterRentComplaint
} from "../../../getData/getData";
import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";

export default {
  name: "ComplaintCompleted",

  components: {
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Rate.name]: Rate,
    rxNavBar
  },

  created() {
    this.initData()
  },

  data() {
    return {
      historyList: [],

      id: this.$route.query.id || '',

      audit: {
        verifier: '张工：1833223223',
        auditTime: '2020-12-02 12:30',
        auditResult: '通过',
        auditOpinion: 'balabalabalabala',
        // type:'false'
        evaluation: '3',
        num: '3'
      },

      //是否可修改
      isModification: true,
      //投诉信息
      complaintInfo: {},
      //受理投诉
      acceptComplaintsInfo: {},
      picList: [],
      //受理结果
      acceptanceResult: {
        processingState: '',
        processDescription: ''
      },
      //受理记录
      acceptanceRecordList: []
    }
  },

  methods: {

    goHouse() {
      this.$router.push('SelectRoom')
    },
    //初始化数据
    initData() {
      const that = this
      let initData = {}
      initData.id = that.id
      afterRentComplaintAcceptInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          console.log(11)
          if(response.data.data.list[0])
            that.acceptComplaintsInfo = response.data.data.list[0]
          if(response.data.data.data){
            that.complaintInfo = response.data.data.data
            that.complaintInfo.roomDetailedAddress = that.complaintInfo.roomDetailedAddress ? that.complaintInfo.roomDetailedAddress : '暂无'
          }
          if(response.data.data.photoList)
            for (let i in response.data.data.photoList)
              that.picList.push(response.data.data.photoList[i].path)

        })
      })
      initData.user_id = globaluserId()
      initData.complaint_id = that.id
      queryComplaintHistory(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.data)
          console.log(222)
          for(let i in response.data.data.data)
            switch(response.data.data.data[i].complaintStatus) {
              case '1':
                that.acceptanceRecordList.push(response.data.data.data[i]);
                break;
              case '2':
              case '3':
                that.historyList.push(response.data.data.data[i]);
                break;
            }
        })
      })

    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },

    submit() {
      let that = this
      if ( !that.id || !that.acceptanceResult.processingState) return
      let data = {}
      data.user_id = globaluserId()
      data.sloveResult = that.acceptanceResult.processDescription
      data.complaintId = that.id
      data.complaintStatus = that.acceptanceResult.processingState
      //提交之后
      updateAfterRentComplaint(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              console.log(response)
              if (200 == response.status) {
                responseUtil.alertMsg(that, '操作成功')
                that.$router.go(-1)
              }
            })
          }
      )
    },

    modification() {
      this.isModification = false
    },

    isEffective() {
      this.acceptanceResult.processingState = '2'
    },

    noEffective() {
      this.acceptanceResult.processingState = '4'
    },

    previewImg(index) {
      ImagePreview(
          {
            images: this.picList,
            startPosition: index,
            onClose() {
              // do something
            },
          }
      )
    },
  }

}
</script>

<style scoped>


.red {
  color: #ff4400;
}

.green {
  color: #62CEB8;
}

.audit {
  font-weight: bold;
  font-size: 15px;
  margin-left: 20px;
  margin-bottom: -10px;
}

.after {
  font-size: 14px;
  color: #909090;
  font-weight: normal;
}


/*隐藏滚动条*/
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.content {
  /*margin-top: 45px;*/
  font-size: 14px;
}

.part {
  margin: 15px;
}

.part-subtitle {
  color: #ff5d3b;
  font-size: 12px;
  font-weight: 900;
  position: relative;
  top: 8px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;
}

.part-inputpart-row-header {
  font-weight: 900;
  font-size: 15px;
}

.otherExpenses-inputpart {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
}

.otherExpenses-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  margin-top: 2px;
}

.otherExpenses-inputpart-row textarea {
  width: 100%;
  height: auto;
  /*padding: 15px 10px 15px 15px;*/
  padding: 15px;
  border: none;
  font-size: 16px;
  font-weight: 900;
  resize: none;
  overflow-y: hidden;
}

.otherExpenses-inputpart-row-bottom {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.otherExpenses-inputpart-row-left {
  display: inline-block;
  width: 47%;
}

.otherExpenses-inputpart-row-right {
  display: inline-block;
  width: 53%;
}

.otherExpenses-inputpart-column {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 15px;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 2px;
}

.traversalRecord-inputpart-column {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 15px 15px 0px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 15px;
  margin-left: 25px;
  font-size: 12px;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-wrap {
  line-height: 24px;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}

.editing {
  display: inline-block;
  background: url("../../../assets/images/Editing.png");
  width: 16px;
  height: 16px;
  background-size: 16px;
  margin-top: -12px;
  margin-right: 15px;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
}

.part-inputpart-row-normaltext {
  color: black;
  font-weight: 900;
}

.part-inputpart-row-redtext {
  /*width: 100%;*/
  flex: auto;
  color: #ff5d3b;
}

.content-divide-position {
  position: relative;
  bottom: 2px;
}

.part-inputpart-isSupport {
  margin-left: 100px;
  margin-right: 18px;
}

.part-inputpart-woman {
  margin-left: 18px;
}

.part-inputpart-textarea {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 8px;
  background-color: white;
}

.part-inputpart-textarea div:first-child {
  margin-top: 0;
}

.part-inputpart-textarea textarea {
  height: 100px;
  background-color: #FaFaFa;
  border-radius: 8px;
  padding: 10px;
  margin: 0px 10px 15px;
  border: none;
  resize: none;
}

.part-inputpart-textarea textarea::-webkit-input-placeholder {
  color: #d8d8d8;
}

.part-button {
  height: 45px;
  border-radius: 10px;
  background-color: #ededed;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 50px;
}

.part-button-enabled {
  background-image: linear-gradient(to right, #ffc274, #ff5d3b);
}

.part-inputpart-row-graytime {
  position: absolute;
  right: 25px;
  color: #d8d8d8;
}

.traversalRecord {
  float: left;
  position: relative;
}

.redRing {
  width: 11px;
  height: 11px;
  border: 1px #ff5d3b solid;
  border-radius: 50%;
  position: absolute;
  top: 35px;
}

.redDot {
  padding: 3.5px;
  border-radius: 50%;
  background-color: #ff5d3b;
  position: absolute;
  top: 2px;
  left: 2px;
}

.redVertical-downline {
  background-color: #FF5D3B;
  width: 1.5px;
  height: 88px;
  left: 4.5px;
  top: 11px;
  position: absolute;
}

.redVertical-upline {
  background-image: linear-gradient(to bottom, white, #FF5D3B);
  width: 1.5px;
  height: 42px;
  left: 4.5px;
  bottom: 11px;
  position: absolute;
}

.picList {
  height: auto;
  padding: 20px 10px;
  overflow: auto;
  position: relative;
}

.num {
  position: absolute;
  left: 15px;
  top: 25px;
  font-size: 10px;
  background-color: rgb(0, 0, 0);
  opacity: .2;
  color: rgb(255, 255, 255);
  padding: 2px 6px;
  display: inline-block;
  border-radius: 10px;
  font-weight: bolder;
  line-height: 10px;
}

.pic {
  margin-right: 10px;
}


</style>
